/* Packages */
import React, { useContext } from 'react'
/* Imported Components */
import { Context } from '../context'
import { ContentfulRichText, Layout } from '../components'
/* Styles */
import styles from './blog.module.scss'
/* Component */
const BlogPage = ({ data }) => {
  const { blog } = useContext(Context)

  return (
    <Layout title='Blog'>
      <h1>Articles</h1>
      <section className={styles.section}>
        {blog.map(article => {
          return (
            <div key={article.id}>
              <h2 key={`${article.id}-title`}>{article.title}</h2>
              <ContentfulRichText
                document={article.json}
                key={`${article.id}-content`}
              />
            </div>
          )
        })}
      </section>
    </Layout>
  )
}
/* Exports */
export default BlogPage
